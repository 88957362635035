import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import Accordion from '../../../../components/Accordion.js'
import Layout from '../../../../components/layout'

const WedAll = ({data}) => {
    return <Layout>
        <Helmet>
            <title>Pandit for Marriage in Bangalore (15+yrs Exp): Book Hindu Wedding Priest</title>
            <meta name="description" content="Need Pandit for Marriage in Bangalore? We provide you the best North and South Indian Hindu Wedding Priest or Pandit for Marriage in Bangalore, India." />
            <script type="application/ld+json">{`
                 {
                  "@context":"https://schema.org",
                  "@type":"FAQPage",
                  "mainEntity": [
                    {
                      "@type":"Question",
                      "name":"Should the customer arrange for Puja Samagri or would the Panditji arrange?",
                      "acceptedAnswer": 
                      {
                        "@type":"Answer",
                        "text":"Customers have the flexibility to book the Puja along with Samagri or book only Panditji for the Puja"
                      }
                    },
                    {
                      "@type":"Question",
                      "name":"Do multiple Pandits call the customer at once upon enquiry, causing them inconvenience?",
                      "acceptedAnswer":
                      {
                        "@type":"Answer",
                        "text":"No. Namaste God values your time and comfort, our team after understanding your requirements arranges a qualified Panditji for the call. In case the customer not satisfied with the Panditji, our team will connect you with another Panditji."
                      }
                    },
                    {
                      "@type":"Question",
                      "name":"Will the Panditji help the customer in choosing good muhurth for ritual?",
                      "acceptedAnswer":
                      {
                        "@type":"Answer",
                        "text":"Yes, our Panditji upon connecting with the customer, will refer to the Panchang and provide an appropriate date and time for the ritual to be performed."
                      }
                    },
                    {
                      "@type":"Question",
                      "name":"How much time would it take for the Puja to be completed?",
                      "acceptedAnswer":
                      {  
                        "@type":"Answer",
                        "text":"Marriage Puja would take about 4-6 hours to get completed. We can arrange shorter or longer pujas as per the customer's requirement."
                      }
                    },
                    {
                      "@type":"Question",
                      "name":"How can the customers pay? Through cash or Wallet or Netbanking?",
                      "acceptedAnswer":
                      {
                        "@type":"Answer",
                        "text":"Customers can pay through Cash or Net Banking or UPI Payments (PayTM, GooglePay) or e-Wallet (PayTM)"
                      }
                    }
                  ]
                }
            `}</script>
            <script type="application/ld+json">{`
                {
                  "@context": "http://schema.org",
                  "@type": "ImageObject",
                  "author": "NamasteGod",
                  "name": "${data.allMarkdownRemark.edges[4].node.frontmatter.title}",
                  "contentUrl": "https://www.namastegod.com${data.allFile.nodes[4].childImageSharp.fluid.src}",
                  "contentLocation": "Bangalore, India"
                }
            `}</script>
            <script type="application/ld+json">{`
                {
                  "@context": "http://schema.org",
                  "@type": "Product",
                  "name": "Pandit for Marriage in Bangalore",
                  "image": "https://www.namastegod.com${data.allFile.nodes[4].childImageSharp.fluid.src}",
                  "description": "Need Pandit for Marriage in Bangalore? We provide you the best North and South Indian Hindu Wedding Priest or Pandit for Marriage in Bangalore, India.",
                  "sku": "0000000008",
                  "mpn": "000008",
                  "brand": {
                    "@type": "Brand",
                    "name": "NamasteGod"
                  },
                  "review": {
                    "@type": "Review",
                    "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5",
                      "bestRating": "5"
                    },
                    "author": {
                      "@type": "Person",
                      "name": "Rekha Anoop"
                    }
                  },
                  "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.8",
                    "reviewCount": "101"
                  },
                  "offers": {
                    "@type": "Offer",
                    "url": "https://www.namastegod.com/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/hindu-wedding-priest-or-pandit-for-marriage-in-bangalore-india",
                    "priceCurrency": "INR",
                    "price": "3100",
                    "priceValidUntil": "2020-12-31",
                    "itemCondition": "https://schema.org/UsedCondition",
                    "availability": "https://schema.org/InStock",
                    "seller": {
                      "@type": "Organization",
                      "name": "NamasteGod"
                    }
                  }
                }
            `}</script>
            <script type="application/ld+json">{`
                {
                  "@context": "http://schema.org",
                  "@type": "LocalBusiness",
                  "name": "Pandit for Marriage in Bangalore",
                  "telephone": "(+91) 81972 49957",
                  "priceRange": "₹₹₹",
                  "url": "https://www.namastegod.com/findonline/hindu/online-pandit-ji-booking-services-for-pooja-or-puja-in-bangalore-india/hindu-wedding-priest-or-pandit-for-marriage-in-bangalore-india",
                  "image": "https://www.namastegod.com${data.allFile.nodes[4].childImageSharp.fluid.src}",
                  "address":
                  {
                    "@type": "PostalAddress",
                    "addressLocality": "Bangalore",
                    "addressRegion": "KA",
                    "addressCountry": "IN",
                    "postalCode": "560078",
                    "streetAddress": "#142/1, 17th Cross, 18th Main, JP Nagar 5th Phase"
                   }
                }
            `}</script>
        </Helmet>
        <div id="main" className="pandit-listing">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1 className="desktop">Pandit for Marriage in Bangalore (15+yrs Exp):<br/>Book Hindu Wedding Priest</h1>
                        <h1 className="mobile">Pandit for Marriage in Bangalore<br/>(15+yrs Exp): Book Hindu<br/>Wedding Priest</h1>
                    </header>
                    <a href="https://g.co/kgs/MciV3q">
                        <span className="reviews">
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <span>Reviews</span>
                        </span>
                    </a>
                    <p>Need <a href='https://en.wikipedia.org/wiki/Purohit' className='no-highlight'>Pandit</a> for Marriage in Bangalore? We provide you the best North and South Indian Hindu Wedding Priest or Pandit for Marriage in Bangalore, India.</p>
                    <Link className="button special" to="#contact">Contact Us</Link>
                    <Accordion
          id="1"
          question=" more"
          answer="<p>Marriages in the hindu traditions are normally done as per guidance of vedic priest or Pandits who are well versed in puja vidhan. Marriage is one of the most celebrated hindu ritual that is performed in Bangalore. Major rituals that are performed during marriage are a follows: Vaakdaanam – boy sends two elders to the bride’s father requesting her hand, Kanyaadaanam – bride’s father gives away his daughter to the boy, Managaladhaaranam – boy ties Managalasutra to the bride’s neck, Saptapadi – boy and bride takes seven steps around the Agni god, Grihapravesam – bride enters the boy’s house by knocking down a cup filled with rice and Praavisya Homam – boy and the bride perform fire ritual. Namaste God provides professional pandits who are well versed and have more than 15+ years of experience in performing Marriage ceremony. Choose pandit ji based on number of puja's performed and book Marriage ceremony with us on namastegod.com</p>
<h3>Frequently Asked Questions</h3>
<ol>
<li class='bold'>Should the customer arrange for Puja Samagri or would the Panditji arrange?
<span>Customers have the flexibility to book the Puja along with Samagri or book only Panditji for the Puja.</span></li>
<li class='bold'>Do multiple Pandits call the customer at once upon enquiry, causing them inconvenience?
<span>No. Namaste God values your time and comfort, our team after understanding your requirements arranges a qualified Panditji for the call. In case the customer not satisfied with the Panditji, our team will connect you with another Panditji.</span></li>
<li class='bold'>Will the Panditji help the customer in choosing good muhurth for ritual?
<span>Yes, our Panditji upon connecting with the customer, will refer to the Panchang and provide an appropriate date and time for the ritual to be performed.</span></li>
<li class='bold'>How much time would it take for the Puja to be completed?
<span>Marriage Puja would take about 4-6 hours to get completed. We can arrange shorter or longer pujas as per the customer's requirement.</span></li>
<li class='bold'>How can the customers pay? Through cash or Wallet or Netbanking?
<span>Customers can pay through Cash or Net Banking or UPI Payments (PayTM, GooglePay) or e-Wallet (PayTM)</span></li>
</ol>"
        />
                </div>
            </section>
            <section id="two" className="spotlights">
                {data.allFile.nodes.map((value, index) => {
                    return <section>
                        <div className="image">
                            <img src={value.childImageSharp.fluid.src} alt={data.allMarkdownRemark.edges[index].node.frontmatter.title} title={data.allMarkdownRemark.edges[index].node.frontmatter.title}/>
                        </div>
                        <div className="content">
                            <div className="inner">
                                <header className="major">
                                    <h3>{data.allMarkdownRemark.edges[index].node.frontmatter.title}</h3>
                                </header>
                                <div className="details">
                                    <span className="label">Years of Experience: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.experience}</span><br/>
                                    <span className="label">Completed his Degree: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.degree}</span><br/>
                                    <span className="label">Well Versed in Puja Traditions as per Regions: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.regions_all}</span><br/>
                                    <span className="label">In Bangalore since: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.bangalore}</span><br/>
                                    <span className="label">Specialized In: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.specialized}</span><br/>
                                    <span className="label">Puja’s Performed: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.pujas}</span>
                                </div>
                            </div>
                        </div>
                    </section>
                })}
            </section>
        </div>

    </Layout>
}

export const query = graphql`
{
  allMarkdownRemark(sort: {fields: frontmatter___order})  {
    edges {
      node {
        frontmatter {
          order
          title
          experience
          degree
          regions_all
          bangalore
          specialized
          pujas
        }
      }
    }
  }
  allFile(filter: {name: {in: ["Pandit Ashutosh Pandey", "Pandit Bharat Kumar", "Pandit Hem Chandra Joshi", "Pandit Jayarama Sharma", "Pandit Subodh Pandey", "Pandit TManjunath"]}}, sort: {fields: name}) {
    nodes {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
}`

export default WedAll